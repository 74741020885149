import React from 'react'
import {Link} from 'react-router-dom';
import TeamOne from "./team/TeamOne";
import TeamTwo from "./team/TeamTwo";

import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import FooterTwo from "../component/footer/FooterTwo";
import Logo from "./header/Header-Logo";
import TabTwo from "./tab/TabTwo";
import styled from 'styled-components';

const Title = styled.h1 `
  font-size: 5.5em;
  text-align: left;
  color: white;
`;

const SubTitle = styled.h3 `
  text-align: left;
  color: white;
`;

const Value = styled.h1 `
  font-size: 3.5em;
  text-align: center;
  color: white;
`;

const Description = styled.h5 `
text-align: left;
color: white;
`;

const ValueDescription = styled.p `
text-align: left;

`;

const AboutUs = () => {
    let title = 'Why PHAT?',
    description = 'Powerhouse Africa, a company committed to addressing challenges across Africa by providing quality and affordable ' 
     + 'tailor-made solutions ranging from Innovation to Engineering. We are committed to improving the quality of life in our communities ' 
     + 'through innovation, creative thinking, and building strong solutions using the latest technologies, processes, and tools available. '
     + 'Our company takes pride in being a client-first team with emphasis on people before the process. '
     + 'We all share one goal: to be trusted in providing sustainable solutions across Africa. Our approach aims at combining trust with innovative and difference-making solutions. '
     + '“PowerHouse” defines our capability, a one-stop for Innovative , Sustainable, and Engineered solutions. ';

    let aboutUsUrl = <img className="w-100" src="/assets/images/about/about-us.png" alt="About PHAT"/>;

    return (
        <>
        <PageHelmet pageTitle="About Us" />
      <Logo
        headertransparent="header--transparent"
        homeLink="/"
        colorblack="color--black"
        logoname="logo.png"
      />
            
      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
        data-black-overlay="7"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-single-page-title text-center pt--100">
                <h2 className="title theme-gradient">Who we are</h2><br />
                 <a className="rn-btn"><Link to="/">Main Page</Link></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                            {/* Start About Area */}
            <section id="about" className="fix">
                <section className="about-area ptb--120  bg_color--4">
                    <section className="about-wrapper">
                        <section className="container">
                            <section className="row row--35 align-items-center">
                                <section className="col-lg-5">
                                    <section className="thumbnail">
                                        {aboutUsUrl}
                                    </section>
                                </section>
                                <section className="col-lg-7">
                                    <section className="about-inner inner">
                                        <section className="section-title">
                                            <Title>
                                                {title}
                                            </Title>

                                            <Description>
                                            {description}
                                            </Description>
                                            
                                        </section>
                                        <section className="row mt--30">
                                            <TabTwo tabStyle="tab-style--1" />
                                        </section>
                                    </section>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
            
            {/* End About Area */}

                            {/* Start Team Area  */}
                <section className="rn-team-wrapper ptb--120 bg_color--1">
                    <section className="rn-team-area">
                        <section className="container">
                            <section className="row">
                                <section className="col-lg-12">
                                    <section className="section-title text-center mb--30">
                                        <h2>Our Senior Management</h2>
                                        <blockquote>"Leadership is lifting a person's vision to high sights, the raising of a person's performance to a higher standard, the building of a personality beyond its normal limitations." —Peter Drucker</blockquote>
                                    </section>
                                </section>
                            </section>
                            <TeamOne column="col-lg-4" teamStyle="" item="3" />
                        </section>
                    </section>
                </section>
                {/* End Team Area  */}

               {/* Start Values Area  */}
               <section className="rn-team-wrapper ptb--120 bg_color--7">
                    <section className="rn-team-area">
                        <section className="container">
                            <section className="row">
                                <section className="col-lg-12">
                                    <section className="section-title text-center mb--30">
                                        <Value>Our Values</Value>
                                    </section>

                                    <div className="single-tab-content">
                                        <div className='about-us-title'>
                                           <ul>
                                                <li>
                                                   <SubTitle>1. Integrity</SubTitle>
                                                  <ValueDescription> Be consistent, honest, ethical, trustworthy and transparent in what we say and do.</ValueDescription>
                                               </li>
                                               <li>
                                                   <SubTitle>2. Passion</SubTitle>
                                                  <ValueDescription> Sustain our energy and enthusiasm to focus on the people we serve while enjoying our work and our families.</ValueDescription>
                                               </li>

                                               <li>
                                                   <SubTitle>3. Dignity and Respect  </SubTitle>
                                                  <ValueDescription> Treat ourselves and each other with consideration, courtesy, dignity and open-mindedness.</ValueDescription>
                                               </li>

                                               <li>
                                                   <SubTitle>4. Collaboration</SubTitle>
                                                  <ValueDescription> Work together and communicate in ways that build a climate of cooperation to leverage our collective talents and jointly achieve our mission.</ValueDescription>
                                               </li>

                                               <li>
                                                   <SubTitle>5. Innovation</SubTitle>
                                                  <ValueDescription> Find new and creative solutions, challenge each other to be ambitious.</ValueDescription>
                                               </li>
                                               <li>
                                                   <SubTitle>6. Effectiveness</SubTitle>
                                                  <ValueDescription> Hold ourselves to the same level of accountability, efficiency and performance that we ask of our recipients.</ValueDescription>
                                               </li>
                                               <li>
                                                   <SubTitle>7. Transparency</SubTitle>
                                                   <ValueDescription>We pride ourselves on being completely transparent with our stakeholders as we journey with them, 
                                                   celebrating our successes just as much as we would learn from our failures.
                                                   We believe in communicating internally and externally with unwavering candour as it is an 
                                                   integral part of our culture while working towards a common goal. </ValueDescription>
                                               </li>

                                           </ul>
                                           </div>
                                       </div>

                                </section>
                            </section>
                        </section>
                    </section>
                </section>
                {/* End Values Area  */}

                {/* Start who we are */}
                {/* <section className="rn-team-wrapper ptb--120 bg_color--5">
                    <section className="rn-team-area">
                        <section className="container">
                            <section className="row">
                                <section className="col-lg-12">
                                    <section className="section-title text-center mb--30">
                                        <h2>Senior Management</h2>
                                        <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                    </section>
                                </section>
                            </section>
                            <TeamTwo column="col-lg-3" teamStyle="" item="6" />
                        </section>
                    </section>
                </section> */}

                {/* End who we are */}

                {/* Start Team Area  */}
                {/* <section className="rn-team-wrapper ptb--120 bg_color--5">
                    <section className="rn-team-area">
                        <section className="container">
                            <section className="row">
                                <section className="col-lg-12">
                                    <section className="section-title text-center mb--30">
                                        <h2>Senior Management</h2>
                                        <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                    </section>
                                </section>
                            </section>
                            <TeamTwo column="col-lg-3" teamStyle="" item="6" />
                        </section>
                    </section>
                </section> */}
                {/* End Team Area  */}

                {/* Start Team Area  */}
                {/* <section className="rn-team-wrapper ptb--120 bg_color--1">
                    <section className="rn-team-area">
                        <section className="container">
                            <section className="row">
                                <section className="col-lg-12">
                                    <section className="section-title text-center mb--30">
                                        <h2>Our Skilled Team</h2>
                                        <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                    </section>
                                </section>
                            </section>
                            <TeamOne column="col-lg-3" teamStyle="team-style--bottom" item="8" />
                        </section>
                    </section>
                </section> */}
                {/* End Team Area  */}


                

            </main>
            {/* End Page Wrapper  */}
            

             {/* Start Back To Top */}
             <section className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </section>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <FooterTwo />
            {/* End Footer Area  */}

        </>
        
    )
}

export default AboutUs