import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiSun, FiCloud, FiLock, FiBarChart, FiWifi} from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Embedded and Distributed Systems Engineering',
        description: 'With the rise of Internet of Everything (IoE) and Artificial Intelligence,' +
        'PowerHouse Africa boasts on its ability to design and build embedded systems that are AI powered and hence add more intelligence to machinery that once only supported manual operations'
    },
    {
        icon: <FiLayers />,
        title: 'Mobile and Web Apps Development with AI capabilities',
        description: 'We build web and mobile applications that transform businesses and organizations to reach their peak performances in sales and impact.' +  
        'We connect businesses with their "right"  clients through well designed algorithms to establish the real needs of the consumers.' 
    },
    {
        icon: <FiLock />,
        title: 'Cyber Security',
        description: 'Powerhouse Africa believes that it is our sworn duty to protect our clients and the community from people who misuse technology.' +
        'We run audits on our clients facilities to profile how likely our clients are, to suffer from cyber-attacks and draw up mitigations strategies to minimise possible damages.'
    },
    { 
        icon: <FiCloud />,
        title: 'Cloud Solutions and Storage',
        description: 'We specialize in building apps that run on the cloud, making the solutions more pockect friendly(cost wise) and accessible to a wide range of clients.' +
        'This also allows us to take on the responsibility of ensuring that our clients data is safe as they consume our products securely by enforcing strong encryptions and data protection policies.'
    },
    {
        icon: <FiBarChart />,
        title: 'Financial Computing and Analysis',
        description: 'We develop solutions that analyze historic data and create financial projections to measure perfomances of various business processes. These projections aid business owners in making' +
        ' informed decisions about their operations with suggestions on what needs to be cut off and where more investments need to be increased so as to maximise profits.'
    },
    { 
        icon: <FiUsers />,
        title: 'IT Consultancy',
        description: 'We offer consultations on any IT projects, by closely examining the challenges faced and the best approach towards addressing raised issues with cost effective.' +
        ' solutions. We are experienced in projects of all sizes ranging from small to large ones.'
    },
    { 
        icon: <FiWifi />,
        title: 'Networking and CCTV Installation',
        description: 'We offer installation of CCTV and networking equipment to meet our clients business needs. This includes smart devices ' +
        ' to increase efficiency with less human intervention and energy saving equipment as we support the Go Green movement.'
    },
    { 
        icon: <FiSun />,
        title: 'Renewable energy',
        description: 'We pride ourselves in taking the leap towards clean and sustainable energy. We believe in securing a healthier environment for the coming generation.' + 
        ' This mission has led us to be part of the green energy movement, by engaging in Solar panel installations and maintenance.'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                              {/* <Link to={{ pathname : "/service-details", state: {title: val.title,details: val.description}}}> */}
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            {/* </Link> */}
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
