import React, {useEffect, useState, Suspense} from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import {FiChevronUp} from "react-icons/fi";
import Logo from "./header/Header-Logo";
import FooterTwo from "../component/footer/FooterTwo";
import styled from "styled-components";
import {config} from "../Constants";
import {Link} from "react-router-dom";

var url = config.url.API_URL;

const ProductTitle = styled.h3`
  color: white;
`;

const Products = () => {
    // News logic
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        fetch(url + "/api/products/")
            .then((res) => res.json())
            .then(
                (data) => {
                    console.log(data);
                    setIsLoaded(true);
                    setProducts(data);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(true);
                }
            );
    }, []);

    const Title = styled.h1`
      font-size: 5.5em;
      text-align: left;
      color: black;
    `;

    return (
        <React.Fragment>
            <PageHelmet pageTitle="Product Details"/>
            <Logo
                headertransparent="header--transparent"
                homeLink="/"
                colorblack="color--black"
                logoname="logo.png"
            />

            {/* Start Breadcrump Area */}
            <div
                className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
                data-black-overlay="7"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="blog-single-page-title text-center pt--100">
                                <h2 className="title theme-gradient">Products</h2>
                                <br/>
                                <a className="rn-btn"><Link to="/">Main page</Link>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Products */}

            <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner-wrapper">
                                <div className="inner">
                                    <section className="row mt--60 mt_sm--40">
                                        <Suspense fallback={<div>Loading</div>}>
                                            {products.map((value, i) => (
                                                <section className="col-lg-4 col-md-6 col-12" key={i}>
                                                    <section className="blog blog-style--1">
                                                        <figure className="thumbnail">
                                                            <Link
                                                                to={{
                                                                    pathname: "/product-details",
                                                                    state: {
                                                                        title: value.title,
                                                                        details: value.description,
                                                                        image: value.image,
                                                                    },
                                                                }}
                                                            >
                                                                <img
                                                                    className="w-100"
                                                                    src={value.image}
                                                                    alt="Product Images"
                                                                />
                                                            </Link>
                                                        </figure>
                                                        <section className="content">
                                                            <ProductTitle>{value.title}</ProductTitle>
                                                            <section className="blog-btn">
                                                                <Link
                                                                    className="rn-btn text-white"
                                                                    to={{
                                                                        pathname: "/product-details",
                                                                        state: {
                                                                            title: value.title,
                                                                            details: value.description,
                                                                            image: value.image,
                                                                        },
                                                                    }}
                                                                >
                                                                    Read More
                                                                </Link>
                                                            </section>
                                                        </section>
                                                    </section>
                                                </section>
                                            ))}
                                        </Suspense>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* End Blog Details */}
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp/>
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <FooterTwo/>
        </React.Fragment>
    );
};

export default Products;
