import React from "react";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";

let data = [
    {
        imagePath: '/assets/images/team/danford-nchembi.png',
        title: 'Danford Nchembi',
        designation: 'Co-Founder/Technical and Innovation Lead',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        imagePath: '/assets/images/team/team-01.jpg',
        title: 'Victor Tesha',
        designation: 'Chief Financial Officer',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
        ]
    },
    {
        imagePath: '/assets/images/team/team-01.jpg',
        title: 'Willem-Jan Van De kolk',
        designation: 'Chief Technical Officer',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    }

];

export default data;