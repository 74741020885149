import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import styledComponents from "styled-components";

const Culture = styledComponents.h6 `
color: white
`;

const TabStyle = styledComponents.h6 `
color: white
`;

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Our Mission",
        tab2 = "Our Vision",
        tab3 = "Values & Culture",
        tab4 = "What sets us apart";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab><TabStyle>{tab1}</TabStyle></Tab>
                                        <Tab><TabStyle>{tab2}</TabStyle></Tab>
                                        {/* <Tab>{tab3}</Tab> */}
                                        <Tab><TabStyle>{tab4}</TabStyle></Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <Culture>To accelerate Africa's transition to sustainable development by 
                                                        providing sustainable and innovative solutions.
                                                    </Culture>
                                      
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   {/* <a href="/service">Awwwards.com <span>- Winner</span></a> 2019 - 2020 */}
                                                   <Culture>To be a trusted organization in creating a better Africa through Sustainable, 
                                                       Engineered & Digital solutions that will help todays and future generations in living a better life.
                                                        We believe that when your brand is authentic, you are halfway down the right path to meeting stakeholders’ needs 
                                                        of which; our stakeholders’ interests are of utmost importance to us. Every aspect of our business communicates this; 
                                                        from our company policy to our social and service interactions</Culture>
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>


                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <p style={{color:'white'}}>1. Solutions that fit our clients</p><span></span>
                                                   <Culture>
                                                   Our relationships with our clients are partnerships rather than us being just a service provider. 
                                                   Bearing in mind that what works today may not work tomorrow and that our clients’ needs continue to/may change, 
                                                   we grow along with them.For us, great partnerships never stand still but always evolve together. 
                                                   We do not propose solutions off-the-shelf or put the client in a box that says they are small, medium, or 
                                                   large and that to us is the easiest way out.We provide solutions that are tailored for the client’s interests and values.</Culture>
                                               </li>
                                               <li>
                                                <p style={{color:'white'}}> 2. People who deliver more</p><span></span>
                                                <Culture>
                                                   We choose our people carefully, they need to demonstrate leadership, agility, original thinking, and prove their integrity. 
                                                   They learn to ask the right questions, rightfully earn their reputation and trust from the clients and maintain it.
                                                </Culture>
                                               </li>
                                               <li>
                                                   <p style={{color: 'white'}}>3. Compliant</p><span></span>
                                                   <Culture>
                                                   Our company and employees adhere to laws, regulations, standards, and ethical practices that apply to
                                                   organizations and industries we work in, both internal policies and procedures as well as federal and state laws.
                                                   </Culture>
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;