import React from "react";

const PortfolioListContent = [
  {
    image: "image-1",
    client: "Bonite Company",
    category: "Distribution Chatbots with Bonite.",
    title:
      "Client engagement through existing platforms such as Telegram and Whatsapp.",
  },
  {
    image: "image-2",
    client: "Engen Company",
    category: "Financial Solutions for Engen",
    title:
      "Implemented solutions and models that organise and simplify workflows in how Engen runs its business..",
  },
  {
    image: "image-3",
    client: "Softmed Company",
    category: "Health Services",
    title:
      "Built and rolled out solutions that have impacted communities positively towards better health care.",
  },
  {
    image: "image-4",
    client: "EgCore Company",
    category: "Retail Solutions",
    title:
      "Offered solutions that connected and managed the sale of lubricants and spare parts in five separate retail outlets.",
  },
  {
    image: "image-4",
    client: "5k Company",
    category: "Entertainment Solutions",
    title:
      "Implemented sophisticated solutions to manage and run a modern night club, that connects over five sales points.",
  },
  {
    image: "image-4",
    client: "Nelson Mandela",
    category: "Research and Development",
    title:
      "Collaborated with academic institutions in research and development of prototypes used for various studies in Tanzania.",
  },
];

export default function PortfolioList(props) {
  const { column } = props;
  const portFolioContent = PortfolioListContent.slice(0, props.item);

  return (
    <React.Fragment>
      <div className="row">
        {portFolioContent.map((val, i) => (
          <div className={`${column}`} key={i}>
            <div className="service service__style--2">
              <div className="content">
                <h3 className="">{val.client}</h3>
                <h3 className="title">
                  <i>{val.category}</i>
                </h3>
                <p>{val.title}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}
