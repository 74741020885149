import React from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Logo from "./header/Header-Logo";
import Footer from "../component/footer/Footer";
import { useLocation } from "react-router-dom";


export default function ServiceDetails()
    {
        const location = useLocation();
        const { details } = location.state;
        const { title } = location.state;
    
        return (
          <React.Fragment>
            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle="Service Details" />
            {/* End Pagehelmet  */}

            <Logo
              headertransparent="header--transparent"
              homeLink="/"
              colorblack="color--black"
              logoname="logo.png"
            />

            {/* Start Breadcrump Area */}
            <div
              className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"
              data-black-overlay="5"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="rn-page-title text-center pt--100">
                      <h2 className="title theme-gradient">{title}</h2><br />
                      <a className="rn-btn" href='/'>Mainpage</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Service Details */}
            <div className="rn-blog-details pt--110 pb--70 bg_color--1">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="inner-wrapper">
                      <div className="inner">
                        {/* <div className="thumbnail"> */}
                          {/* <img
                            src="/assets/images/blog/bl-big-01.jpg" alt="Blog Images"
  
                          /> */}
                        {/* </div> */}
                        <p className="mt--40">{details}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* End Service Details */}

            {/* Start Back To Top */}
            <div className="backto-top">
              <ScrollToTop showUnder={160}>
                <FiChevronUp />
              </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />
          </React.Fragment>
        );
    }