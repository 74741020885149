import React from "react";
import PageHelmet from "../component/common/Helmet";
import { useLocation } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Logo from "./header/Header-Logo";
import FooterTwo from "../component/footer/FooterTwo";

export default function ProjectDetails() {
  const location = useLocation();
  const { details } = location.state;
  const { title } = location.state;

  return (
    <React.Fragment>
      <PageHelmet pageTitle="Project Details" />
      <Logo
        headertransparent="header--transparent"
        homeLink="/"
        colorblack="color--black"
        logoname="logo.png"
      />

      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
        data-black-overlay="7"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-single-page-title text-center pt--100">
                <h2 className="title theme-gradient">{title}</h2><br />
                <a className="rn-btn" href='/'>Mainpage</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Blog Details */}
      <div className="rn-blog-details pt--110 pb--70 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  {/* <div className="thumbnail">
                    <img
                      // src="/assets/images/blog/bl-big-01.jpg"
                      src={ image }
                      alt="Project Images"
                    />
                  </div> */}
                  <p className="mt--40">{details}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End Blog Details */}
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <FooterTwo />
    </React.Fragment>
  );
}
