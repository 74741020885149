import React from "react";
import PageHelmet from "../component/common/Helmet";
import { FiClock, FiMessageCircle } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Logo from "./header/Header-Logo";
import FooterTwo from "../component/footer/FooterTwo";

export default function NewsDetails() {
  const location = useLocation();
  const { details } = location.state;
  const { title } = location.state;
  const { dateTimeCreated } = location.state;
  const userOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const localDate = new Date(dateTimeCreated);
  const utcDate = new Date(localDate.getTime() + userOffset).toString();

  console.log(utcDate);

  return (
    <React.Fragment>
      <PageHelmet pageTitle="News Details" />
      <Logo
        headertransparent="header--transparent"
        homeLink="/"
        colorblack="color--black"
        logoname="logo.png"
      />

      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
        data-black-overlay="7"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-single-page-title text-center pt--100">
                <h2 className="title theme-gradient">{title}</h2>
                <ul className="blog-meta d-flex justify-content-center align-items-center">
                  <li>
                    <FiClock />
                    {utcDate}
                  </li>
                  <li>
                    <FiMessageCircle />
                    15 Comments
                  </li>
                  {/* <li><FiUser />NipaBali</li>
                                        <li><FiMessageCircle />15 Comments</li>
                                        <li><FiHeart />Like</li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Blog Details */}
      <div className="rn-blog-details pt--110 pb--70 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  {/* <div className="thumbnail">
                    <img
                      src={ image }
                      alt="Blog Images" */}
                    {/* /> */}
                    {/* <img src={image} alt="Blog Images"/> */}
                  {/* </div> */}
                  <p className="mt--40">{details}</p>
        
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}

      {/* End Blog Details */}

      {/* Start BLog Comment Form  */}
      <div className="blog-comment-form pb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner">
                <h3 className="title mb--40 fontWeight500">Leave a Reply</h3>
                <form action="#">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="rnform-group">
                        <input type="text" placeholder="Name" />
                      </div>
                      <div className="rnform-group">
                        <input type="email" placeholder="Email" />
                      </div>
                      <div className="rnform-group">
                        <input type="text" placeholder="Website" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="rnform-group">
                        <textarea type="text" placeholder="Comment"></textarea>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="blog-btn">
                        {/* <a className="rn-button-style--2 btn-solid" href="#"></a> */}
                        <Link
                          className="rn-button-style--2 btn-solid"
                          to="/blog-details"
                        >
                          <span>SEND MESSAGE</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End BLog Comment Form  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <FooterTwo />
    </React.Fragment>
  );
}
