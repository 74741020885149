import React, {useEffect, useState, Suspense } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../../elements/header/HeaderThree";
import FooterTwo from "../footer/FooterTwo";
import ContactThree from "../../elements/contact/ContactThree";
import ServiceList from "../../elements/service/ServiceList";
import { Link } from 'react-router-dom';
import { config } from '../../Constants';


var url = config.url.API_URL;

const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Better communities lead to a better world',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]

const Main = () => {

    // News logic
    const [error, setError] = useState(null);
    const[isLoaded, setIsLoaded] = useState(false);
    const [news, setNews] = useState([]);
    const [projectsUndertaken, setProjectsUndertaken] = useState([]);

    useEffect(() => {
        fetch(url + "/api/news/")
        .then(res => res.json())
        .then((data) => 
        { 
            console.log(data)
            setIsLoaded(true); 
            setNews(data);
        },
        (error) => 
        {
            setIsLoaded(true);
            setError(true);
        }
        )
    }, [])

    useEffect(() => {
        fetch(url + "/api/projects-undertaken/")
        .then(res => res.json())
        .then((data) => 
        { 
            console.log(data)
            setIsLoaded(true); 
            setProjectsUndertaken(data);
        },
        (error) => 
        {
            setIsLoaded(true);
            setError(true);
        }
        )
    }, [])

    return (
        <section className="active-dark">
            <Helmet pageTitle="PHAT" />

            <HeaderThree homeLink="/" logo="symbol-dark" color="color-black"/>
            {/* Start Slider Area   */}
            <section id="home" className="fix">
                <section className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <section className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25" key={index}>
                            <section className="container">
                                <section className="row">
                                    <section className="col-lg-12 col-md-12 col-sm-12">
                                        <br />
                                        <section className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            <h1 className="title">Bringing<br/>
                                            <TextLoop>
                                                <span>innovative products,</span>
                                                <span>creative thinking,</span>
                                                <span>sustainable solutions</span>
                                            </TextLoop>{" "}
                                            </h1>
                                            <h2>to the African market.</h2>
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <section className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></section> : ''}
                                        </section>
                                    </section>
                                </section>
                            </section>
                        </section>
                    ))}
                    {/* End Single Slide */}
                </section>
            </section>
            {/* End Slider Area   */} 


            {/* Start Service Area  */}
            <section id="service" className="fix">
                <section className="service-area creative-service-wrapper ptb--120 bg_color--5" >
                    <section className="container">
                        <section className="row">
                            <section className="col-lg-12">
                                <section className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                    <h2 className="title">Our Focus</h2>
                                    {/* <p>We pride ourselves in delivering services with designs inspired by our clients' needs. 
                                        We monitor our service performance continuously to make sure our clients consistently prefer us to be the 
                                        benchmark across every industry.
                                    </p> */}
                                </section>
                            </section>
                        </section>
                        <section className="row creative-service">
                            <section className="col-lg-12">
                                <ServiceList item="8" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </section>
                        </section>
                    </section>
                </section>  
            </section>

            {/* End Service Area  */} 
            <section id="portfolio" className="fix">
                <section className="rn-blog-area ptb--120 bg_color--5 mb-dec--30">
                    <section className="container">
                        <section className="row align-items-end">
                            <section className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <section className="section-title text-center">
                                    <h2>Projects</h2>
                                    <p>Powerhouse has a huge experience in projects of all sizes. <br />Below are some of our completed projects.</p>
                                </section>
                            </section>
                        </section>
                        <section className="row mt--60 mt_sm--40">
                            <Suspense fallback={<div>Loading</div>}>
                                {/* <PortfolioList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" /> */}
                                {projectsUndertaken.map((value , i ) => (
                                <section className="col-lg-4 col-md-6 col-12" key={i}>
                                    <section className="blog blog-style--1">
                                        <figure className="thumbnail">
                                        <Link to={{ pathname : "/project-details", state: {title: value.title,details: value.details,image:value.image}}}>
                                                {/* <img className="w-100" src={`/assets/images/blog/blog-04.jpg`} alt="Project Images"/> */}
                                                <img className="w-100" src={ value.image } alt="Project Images"/>
                                            </Link>
                                        </figure>
                                        <section className="content">
                                            
                                            <h3 className="title">{value.client}</h3>
                                            <h5 className="title">{value.title}</h5>
                                            <section className="blog-btn">
                                                <Link className = "rn-btn text-white" to={{ pathname : "/project-details", state: {title: value.title,details: value.details,image:value.image}}}>Read More</Link>
                                            </section>
                                        </section>
                                    </section>
                                </section>
                            ))}
                            </Suspense>
                            </section>
                        </section>
                    </section>
                </section>  

            {/* End Portfolio Area */}

            {/* Start Blog Area */}
            <section id="blog" className="fix">
                <section className="rn-blog-area ptb--120 bg_color--5 mb-dec--30">
                    <section className="container">
                        <section className="row align-items-end">
                            <section className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <section className="section-title text-center">
                                    <h2>Latest News</h2>
                                    <p>We are always doing our best to keep our clients informed <br />on our launches and upcoming events.</p>
                                </section>
                            </section>
                        </section>
                        <section className="row mt--60 mt_sm--40">
                           {/* Old news section */}
                           <Suspense fallback={<div>Loading</div>}>
                            {news.map((value , i ) => (
                                <section className="col-lg-4 col-md-6 col-12" key={i}>
                                    <section className="blog blog-style--1">
                                        <figure className="thumbnail">
                                            <Link to={{ pathname : "/news-details", state: {title: value.title,details: value.details, dateTimeCreated: value.date_time_created, image:value.image}}}>
                                                
                                                <img className="w-100" src={ value.image } alt="News"/>
                                            </Link>
                                        </figure>
                                        <section className="content">
                                            
                                            <p className="blogtype">{value.category.name}</p>
                                            <h4 className="title">{value.title}</h4>
                                            <section className="blog-btn">
                                                <Link className = "rn-btn text-white" to={{ pathname : "/news-details", state: {title: value.title,details: value.details, dateTimeCreated: value.date_time_created, image:value.image}}}>Read More</Link>
                                            </section>
                                        </section>
                                    </section>
                                </section>
                            ))}
                            </Suspense>
                        </section>    
                    </section>    
                </section>
            </section>
            {/* End Blog Area */}

            {/* Start COntact Area */}  vc  
            <section id="contact" className="fix">
                <section className="rn-contact-area ptb--120 bg_color--1">
                    <ContactThree contactImages="/assets/images/about/contact-us.jpeg" contactTitle="Hire Us." />
                </section>
            </section>
            {/* End COntact Area */}

            <FooterTwo />
            {/* Start Back To Top */}
            <section className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </section>
            {/* End Back To Top */}
            
        </section>
    )
}

export default Main;
